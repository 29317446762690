import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader
} from "../../../../_metronic/_partials/controls";
import { getAnswersByScenario } from "../../../../api/answer";
import { getGroups } from "../../../../api/group";
import { getSceneById } from "../../../../api/scene";
import { alertError } from "../../../../utils/logger";
import Table, {
	pointsFormatter,
	timeFormatter
} from "../../../components/tables/table";

function getData(groups, answers) {
	let data = [];
	groups.forEach((group) => {
		let groupAnswers = answers.filter((answer) => {
			return answer.group === group._id;
		});
		let result = null;
		if (groupAnswers.length)
			result = groupAnswers?.reduce((a, b) => ({
				score: a.score + b.score,
				time: a.time + b.time,
			}));
		if (result) {
			data = data.concat({
				group: group.fullName,
				pts: result.score,
				time: result.time,
			});
		}
	});

	data.sort((a, b) => {
		if (a.pts !== b.pts) {
			return b.pts - a.pts;
		}
		return a.time - b.time;
	});
	return data;
}

export default function ViewScenePage() {
	const [data, setData] = useState([]);
	const [scenario, setScenario] = useState();
	const [refresh, setRefresh] = useState(false);
	const sceneId = useParams().id;

	const columns = [
		{ dataField: "group", text: "Group" },
		{
			dataField: "pts",
			text: "Correct",
			align: "center",
			headerAlign: "center",
			formatter: pointsFormatter,
			sort: true,
		},
		{
			dataField: "time",
			text: "Time",
			align: "center",
			headerAlign: "center",
			formatter: timeFormatter,
			sort: true,
		},
	];

	useEffect(() => {
		getSceneById(sceneId)
			.then((res) => {
				if (res.status === 200) {
					setScenario(res.data);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get the scenario.",
				});
			});

		getAnswersByScenario(sceneId)
			.then((resAnswers) => {
				if (resAnswers.status === 200) {
					getGroups()
						.then((res) => {
							if (res.status === 200) {
								setData(getData(res.data, resAnswers.data));
								setRefresh(false);
							}
						})
						.catch((error) => {
							alertError({
								error: error,
								customMessage: "Could not get groups.",
							});
						});
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get groups.",
				});
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader
					title={`Scenario | ${scenario?.fullName || ""}`}
				></CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
				</CardBody>
			</Card>
		</>
	);
}
