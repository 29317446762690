import { API, authClient } from "..";

// Get all scenes
export const getScenes = () => {
	return authClient().get(`${API}/scene`);
};

// Get the specified scene
export const getSceneById = (id) => {
	return authClient().get(`${API}/scene/${id}`);
};

// Get the specified scene stats
export const getSceneStatistics = (id) => {
	return authClient().get(`${API}/scene/stats/${id}`);
};
