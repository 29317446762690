/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import AssessmentIcon from "@material-ui/icons/Assessment";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import LocalMoviesIcon from "@material-ui/icons/LocalMovies";
import SportsEsportsIcon from "@material-ui/icons/SportsEsports";
import React from "react";
import SVG from "react-inlinesvg";
import { shallowEqual, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive, toAbsoluteUrl } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);
	const isModerator = user?.role === "moderator" ? true : false;
	const location = useLocation();
	const getMenuItemActive = (url, hasSubmenu = false) => {
		return checkIsActive(location, url)
			? ` ${!hasSubmenu &&
					"menu-item-active"} menu-item-open menu-item-not-hightlighted`
			: "";
	};

	return (
		<>
			{/* begin::Menu Nav */}
			<ul className={`menu-nav ${layoutProps.ulClasses}`}>
				{/*begin::1 Level*/}
				<li
					className={`menu-item ${getMenuItemActive(
						"/dashboard",
						false
					)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/dashboard">
						<span className="svg-icon menu-icon">
							<SVG
								src={toAbsoluteUrl(
									"/media/svg/icons/Design/Layers.svg"
								)}
							/>
						</span>
						<span className="menu-text">Dashboard</span>
					</NavLink>
				</li>
				{isModerator && (
					<li className="menu-section">
						<h4 className="menu-text">{"Game"}</h4>
						<i className="menu-icon ki ki-bold-more-hor icon-md"></i>
					</li>
				)}
				<li
					className={`menu-item ${getMenuItemActive(
						"/results",
						false
					)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/results">
						<span className="menu-icon">
							<AssessmentIcon />
						</span>
						<span className="menu-text">Results</span>
					</NavLink>
				</li>
				{isModerator ? (
					<li
						className={`menu-item ${getMenuItemActive(
							"/ranking-game",
							false
						)}`}
						aria-haspopup="true"
					>
						<NavLink
							className="menu-link"
							to={`/ranking-game/${user?.game}`}
						>
							<span className="menu-icon">
								<EmojiEventsIcon />
							</span>
							<span className="menu-text">Ranking</span>
						</NavLink>
					</li>
				) : (
					<>
						<li className="menu-section">
							<h4 className="menu-text">ENTITIES</h4>
							<i className="menu-icon ki ki-bold-more-hor icon-md"></i>
						</li>
						<li
							className={`menu-item ${getMenuItemActive(
								"/games",
								false
							)} ${getMenuItemActive("/edit-game", false)}`}
							aria-haspopup="true"
						>
							<NavLink className="menu-link" to="/games">
								<span className="menu-icon">
									<SportsEsportsIcon />
								</span>
								<span className="menu-text">Games</span>
							</NavLink>
						</li>
						<li
							className={`menu-item ${getMenuItemActive(
								"/groups",
								false
							)} ${getMenuItemActive("/edit-group", false)}`}
							aria-haspopup="true"
						>
							<NavLink className="menu-link" to="/groups">
								<span className="menu-icon">
									<GroupWorkIcon />
								</span>
								<span className="menu-text">Groups</span>
							</NavLink>
						</li>

						<li className="menu-section">
							<h4 className="menu-text">ESCAPE ROOM</h4>
							<i className="menu-icon ki ki-bold-more-hor icon-md"></i>
						</li>
						<li
							className={`menu-item ${getMenuItemActive(
								"/scenarios",
								false
							)} ${getMenuItemActive("/scenario", false)}`}
							aria-haspopup="true"
						>
							<NavLink className="menu-link" to="/scenarios">
								<span className="menu-icon">
									<LocalMoviesIcon />
								</span>
								<span className="menu-text">Scenarios</span>
							</NavLink>
						</li>
						<li
							className={`menu-item ${getMenuItemActive(
								"/quizzes",
								false
							)} ${getMenuItemActive("/quiz", false)}`}
							aria-haspopup="true"
						>
							<NavLink className="menu-link" to="/quizzes">
								<span className="menu-icon">
									<ContactSupportIcon />
								</span>
								<span className="menu-text">Quizzes</span>
							</NavLink>
						</li>
						<li className="menu-section">
							<h4 className="menu-text">MANAGE</h4>
							<i className="menu-icon ki ki-bold-more-hor icon-md"></i>
						</li>
						<li
							className={`menu-item ${getMenuItemActive(
								"/admins",
								false
							)} ${getMenuItemActive("/edit-admin", false)}`}
							aria-haspopup="true"
						>
							<NavLink className="menu-link" to="/admins">
								<span className="menu-icon">
									<AssignmentIndIcon />
								</span>
								<span className="menu-text">
									Administrators
								</span>
							</NavLink>
						</li>
					</>
				)}

				{/*end::1 Level*/}
			</ul>

			{/* end::Menu Nav */}
		</>
	);
}
