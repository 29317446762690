import {
  Button,
  MuiThemeProvider,
  TextField,
  createMuiTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
  deleteGame,
  getGameById,
  postGame,
  updateGame,
} from "../../../../api/game";
import { alertError, alertInfo, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { useSkeleton } from "../../../hooks/useSkeleton";

// Create theme for delete button (red)
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#F64E60",
    },
  },
});

function getEmptyGame() {
  return {
    fullName: "",
  };
}

export default function EditGamesPage() {
  const [game, setGame] = useState(getEmptyGame());
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const gameId = useParams().id;
  const history = useHistory();

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  useEffect(() => {
    if (!gameId) {
      disableLoadingData();
      return;
    }
    getGameById(gameId)
      .then((res) => {
        if (res.status === 200) {
          setGame(res.data);
          disableLoadingData();
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get game.",
        });
        history.push("/games");
      });
  }, [gameId, disableLoadingData, history]);

  function saveGame() {
    if (!gameId) {
      postGame(game)
        .then((res) => {
          if (res.status === 201) {
            alertSuccess({
              title: "Saved!",
              customMessage: "Game successfully created.",
            });
            history.push("/games");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save game.",
          });
        });
    } else {
      updateGame(gameId, game)
        .then((res) => {
          if (res.status === 200) {
            alertSuccess({
              title: "Saved!",
              customMessage: "Changes successfully saved.",
            });
            history.push("/games");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save changes.",
          });
        });
    }
  }

  const handleChange = (element) => (event) => {
    setGame({ ...game, [element]: event.target.value });
  };

  if (isLoadingData) return <ContentSkeleton />;
  else
    return (
      <>
        <Card>
          <CardHeader title="Edit game"></CardHeader>
          <CardBody>
            <TextField
              id={`fullName`}
              label="Full name"
              value={game.fullName}
              onChange={handleChange("fullName")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
          </CardBody>
        </Card>
        <Button
          onClick={() => history.push("/games")}
          variant="outlined"
          style={{ marginRight: "20px" }}
        >
          Back
        </Button>
        <Button
          onClick={() => saveGame()}
          variant="outlined"
          color="primary"
          style={{ marginRight: "20px" }}
        >
          Save game
        </Button>
        {gameId && (
          <>
            <MuiThemeProvider theme={theme}>
              <Button
                onClick={() => setOpenConfirmDialog(true)}
                variant="outlined"
                color="secondary"
              >
                Delete game
              </Button>
            </MuiThemeProvider>

            <ConfirmDialog
              title={"Are you sure you want to delete this game?"}
              open={openConfirmDialog}
              setOpen={setOpenConfirmDialog}
              onConfirm={() => {
                deleteGame(gameId)
                  .then((res) => {
                    if (res.status === 204) {
                      alertSuccess({
                        title: "Deleted!",
                        customMessage: "Game deleted successfully",
                      });
                      history.push("/games");
                    }
                  })
                  .catch((error) => {
                    alertError({
                      error: error,
                      customMessage: "Could not delete economic sector.",
                    });
                  });
              }}
            />
          </>
        )}
      </>
    );
}
