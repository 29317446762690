import { Dialog, DialogContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { formatTime } from "../../../utils/helpers";
import timeContainer from "../../assets/ranking/contador-tiempo.svg";
import profileContainer from "../../assets/ranking/perfil-ranking.svg";
import podio1st from "../../assets/ranking/podio-1st.png";
import podio2nd from "../../assets/ranking/podio-2nd.png";
import podio3rd from "../../assets/ranking/podio-3rd.png";

import { SERVER_URL } from "../../../api";
import "./ranking.css";

const RankingDialog = (props) => {
  const { open, setOpen, data, title } = props;

  const firstGroup = data[0];
  const secondGroup = data[1];
  const thirdGroup = data[2];

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => setOpen(null)}
      aria-labelledby="ranking-dialog"
    >
      <DialogContent className="bg-confeti-ranking">
        <div>
          <div className="container-fluid">
            <div className="d-flex justify-content-end">
              <CloseIcon
                className="close-dialog-icon"
                onClick={() => setOpen(null)}
              />
            </div>
            <div className="row text-center pt-5 justify-content-center">
              <h1 className="title6 txt-blue">{title}</h1>
            </div>
            <div
              className="row text-center d-flex justify-content-center"
              style={{ marginTop: "3%" }}
            >
              <div className="col-12 d-flex justify-content-center align-items-end">
                {thirdGroup && (
                  <div className="d-flex flex-column align-items-center">
                    <div className="d-flex align-items-center">
                      <div className="txt-brown txt28 num-sellos ml-3">
                        {thirdGroup?.score}
                      </div>
                    </div>
                    <div className="mt-1" style={{ position: "relative" }}>
                      <img
                        src={timeContainer}
                        alt=""
                        className="rankingContainer"
                      />
                      <div
                        className="rankingText-time title8 txtWhite"
                        style={{
                          position: "absolute",
                          top: "21%",
                          left: "40%",
                        }}
                      >
                        {formatTime(thirdGroup?.time)}
                      </div>
                    </div>
                    <div className="d-flex flex-column align-items-center mt-2">
                      <img src={podio3rd} width={90}></img>
                      <div className="figure-line"></div>
                    </div>
                    <div className="position-relative">
                      <div className="teamfoto-small">
                        <img
                          src={SERVER_URL + "/" + thirdGroup?.image}
                          style={{
                            width: thirdGroup?.image ? "" : "0px",
                          }}
                        />
                      </div>
                      <img src={profileContainer} alt="" width={"50%"} />
                    </div>
                    <div className="txt18 txt-blue mt-2 mb-1">
                      {thirdGroup?.fullName || "Nombre Equipo"}
                    </div>
                  </div>
                )}
                {firstGroup && (
                  <div className="d-flex flex-column align-items-center">
                    <div className="d-flex align-items-center">
                      <div className="txt-brown txt28 num-sellos ml-3">
                        {firstGroup?.score}
                      </div>
                    </div>
                    <div className="mt-1" style={{ position: "relative" }}>
                      <img
                        src={timeContainer}
                        alt=""
                        className="rankingContainer"
                      />
                      <div
                        className="rankingText-time title8 txtWhite"
                        style={{
                          position: "absolute",
                          top: "21%",
                          left: "40%",
                        }}
                      >
                        {formatTime(firstGroup?.time)}
                      </div>
                    </div>
                    <div className="d-flex flex-column align-items-center mt-2">
                      <img src={podio1st} width={90}></img>
                      <div className="figure-line"></div>
                    </div>
                    <div className="position-relative">
                      <div className="teamfoto-small">
                        <img
                          src={SERVER_URL + "/" + firstGroup?.image}
                          style={{
                            width: firstGroup?.image ? "" : "0px",
                          }}
                        />
                      </div>
                      <img src={profileContainer} alt="" width={"50%"} />
                    </div>
                    <div className="txt18 txt-blue mt-2 mb-1">
                      {firstGroup?.fullName || "Nombre Equipo"}
                    </div>
                  </div>
                )}
                {secondGroup && (
                  <div className="d-flex flex-column align-items-center">
                    <div className="d-flex align-items-center">
                      <div className="txt-brown txt28 num-sellos ml-3">
                        {secondGroup?.score}
                      </div>
                    </div>
                    <div className="mt-1" style={{ position: "relative" }}>
                      <img
                        src={timeContainer}
                        alt=""
                        className="rankingContainer"
                      />
                      <div
                        className="rankingText-time title8 txtWhite"
                        style={{
                          position: "absolute",
                          top: "21%",
                          left: "40%",
                        }}
                      >
                        {formatTime(secondGroup?.time)}
                      </div>
                    </div>
                    <div className="d-flex flex-column align-items-center mt-2">
                      <img src={podio2nd} width={90}></img>
                      <div className="figure-line"></div>
                    </div>
                    <div className="position-relative">
                      <div className="teamfoto-small">
                        <img
                          src={SERVER_URL + "/" + secondGroup?.image}
                          style={{
                            width: secondGroup?.image ? "" : "0px",
                          }}
                        />
                      </div>
                      <img src={profileContainer} alt="" width={"50%"} />
                    </div>
                    <div className="txt18 txt-blue mt-2 mb-1">
                      {secondGroup?.fullName || "Nombre Equipo"}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default RankingDialog;
