import { Cancel, CheckCircle } from "@material-ui/icons";
import CheckIcon from "@material-ui/icons/Check";
import CheckBox from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";
import ClearIcon from "@material-ui/icons/Clear";
import TimerIcon from "@material-ui/icons/Timer";
import React, { useState } from "react";
import { Image } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "react-datetime/css/react-datetime.css";
import { SERVER_URL } from "../../../api";
import "../../../customStyles/bootstrap_table.css";
import selloVerde from "../../assets/ranking/sello.svg";

const { SearchBar, ClearSearchButton } = Search;

export const buttonsStyle = {
	maxWidth: "30px",
	maxHeight: "30px",
	minWidth: "30px",
	minHeight: "30px",
	marginLeft: "1em",
};

export function booleanFormatter(cell) {
	return cell ? (
		<CheckBox style={{ color: "#1B3A94" }} />
	) : (
		<CheckBoxOutlineBlank style={{ color: "#1B3A94" }} />
	);
}

export function resultFormatter(cell) {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				padding: "2px",
				alignItems: "center",
			}}
		>
			{cell && (
				<>
					<div
						style={{
							width: "95px",
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						{!cell.hasOwnProperty("correct") ? (
							<>
								{cell.pts}

								<CheckCircle
									style={{
										marginLeft: "5px",
										height: "15px",
										width: "15px",
									}}
								/>
							</>
						) : cell.correct ? (
							<>
								{cell.pts}
								<CheckIcon
									style={{
										marginLeft: "5px",
										height: "20px",
										width: "20px",
										color: "green",
									}}
								/>
							</>
						) : (
							<>
								{cell.pts}
								<ClearIcon
									style={{
										marginLeft: "5px",
										height: "20px",
										width: "20px",
										color: "red",
									}}
								/>
							</>
						)}
					</div>
					<div
						style={{
							width: "95px",
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						{timeFormat(cell.time)}

						<TimerIcon
							style={{
								marginLeft: "5px",
								height: "15px",
								width: "15px",
							}}
						/>
					</div>
				</>
			)}
		</div>
	);
}

export function imageFormatter(imageUrl) {
	return imageUrl ? (
		<Image
			src={SERVER_URL + "/" + imageUrl}
			roundedCircle
			width={40}
			height={40}
		></Image>
	) : (
		<div></div>
	);
}

export function correctAnswerFormatter(cell) {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				padding: "2px",
				alignItems: "center",
			}}
		>
			<div
				style={{
					width: "95px",
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				{cell >= 0 ? (
					<>
						{cell}
						<CheckCircle
							className="ml-2"
							style={{
								color: "green",
								height: "15px",
								width: "15px",
							}}
						/>
					</>
				) : cell < 0 ? (
					<>
						{cell}
						<Cancel
							className="ml-2"
							style={{
								color: "red",
								height: "15px",
								width: "15px",
							}}
						/>
					</>
				) : (
					<></>
				)}
			</div>
		</div>
	);
}

export function pointsFormatter(cell) {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				padding: "2px",
				alignItems: "center",
			}}
		>
			<div
				style={{
					width: "95px",
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				{cell >= 0 ? (
					<>
						{cell}
						<CheckCircle
							className="ml-2"
							style={{
								color: "green",
								height: "15px",
								width: "15px",
							}}
						/>
					</>
				) : cell < 0 ? (
					<>
						{cell}
						<Cancel
							className="ml-2"
							style={{
								color: "red",
								height: "15px",
								width: "15px",
							}}
						/>
					</>
				) : (
					<></>
				)}
			</div>
		</div>
	);
}

export function stampsFormatter(cell) {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				padding: "2px",
				alignItems: "center",
			}}
		>
			<div
				style={{
					width: "95px",
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				{typeof cell != "boolean" ? (
					<>
						{cell}

						<img
							src={selloVerde}
							style={{
								marginLeft: "5px",
								height: "15px",
								width: "15px",
							}}
						/>
					</>
				) : cell === true ? (
					<>
						<CheckIcon
							style={{
								marginInline: "5px",
								height: "20px",
								width: "20px",
								color: "green",
							}}
						/>
						Correct
					</>
				) : (
					<>
						<ClearIcon
							style={{
								marginInline: "5px",
								height: "20px",
								width: "20px",
								color: "red",
							}}
						/>
						Incorrect
					</>
				)}
			</div>
		</div>
	);
}

export function timeFormatter(cell) {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				padding: "2px",
				alignItems: "center",
			}}
		>
			{cell && (
				<div
					style={{
						width: "95px",
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					{timeFormat(cell)}

					<TimerIcon
						style={{
							marginLeft: "5px",
							height: "15px",
							width: "15px",
						}}
					/>
				</div>
			)}
		</div>
	);
}

const buildDate = (date) => {
	if (Object.prototype.toString.call(date) !== "[object Date]") return;
	const displayDate = `${date.getDate()}/${date.getMonth() +
		1}/${date.getFullYear()}  ${date.getHours()}:${String(
		date.getMinutes()
	).padStart(2, "0")}`;
	return displayDate;
};

const timeFormat = (cell) => {
	let minutes = Math.floor(cell / 60);
	let seconds = cell - minutes * 60;
	if (minutes) return `${minutes} min ${seconds} s`;
	return `${seconds} s`;
};

export function dateFormatter(cell) {
	let date = new Date(cell);
	date = buildDate(date);

	return date;
}

const customTotal = (from, to, size) => (
	<span
		className="react-bootstrap-table-pagination-total"
		style={{ paddingLeft: "5px" }}
	>
		Showing {from}-{to} of {size} results
	</span>
);

const pagination = paginationFactory({
	page: 1,
	sizePerPage: 10,
	lastPageText: ">>",
	firstPageText: "<<",
	nextPageText: ">",
	prePageText: "<",
	showTotal: true,
	paginationTotalRenderer: customTotal,
	alwaysShowAllBtns: false,
	onPageChange: function(page, sizePerPage) {},
	onSizePerPageChange: function(page, sizePerPage) {},
});

/*function onColumnMatch({
  searchText,
  value,
  column,
  row
}) {
  if (searchText && value && typeof value === 'string') {
    return value.toLowerCase().startsWith(searchText)
  }
  return true;
}*/

export default function Table({ data, columns, defaultSorted, ...tableProps }) {
	const [year, setYear] = useState(null);
	return (
		<ToolkitProvider
			bootstrap4
			keyField="id"
			data={data}
			columns={columns}
			search
		>
			{(props) => (
				<div>
					<SearchBar placeholder="Search" {...props.searchProps} />
					<ClearSearchButton
						className="ml-3"
						{...props.searchProps}
					/>

					<br />
					<br />
					<BootstrapTable
						wrapperClasses="table-responsive"
						classes="table table-head-custom table-vertical-center overflow-hidden"
						bordered={false}
						// remote
						pagination={pagination}
						striped
						defaultSorted={defaultSorted}
						{...tableProps}
						{...props.baseProps}
					/>
				</div>
			)}
		</ToolkitProvider>
	);
}

export function ResultsTable({
	data,
	columns,
	header,
	expandRow,
	...tableProps
}) {
	return (
		<ToolkitProvider
			bootstrap4
			keyField="scenario"
			data={data}
			columns={columns}
		>
			{(props) => (
				<div>
					<br />
					<br />
					<div className="results-table">
						<BootstrapTable
							wrapperClasses="table-responsive"
							classes="table table-head-custom table-vertical-center overflow-scroll"
							bordered={false}
							striped
							expandRow={expandRow}
							caption={header}
							{...tableProps}
							{...props.baseProps}
						/>
					</div>
				</div>
			)}
		</ToolkitProvider>
	);
}

export function QuizResultsTable({ data, columns, ...tableProps }) {
	return (
		<ToolkitProvider bootstrap4 keyField="id" data={data} columns={columns}>
			{(props) => (
				<div>
					<br />
					<BootstrapTable
						wrapperClasses="table-responsive"
						classes="table-expanded table-expanded-head-solid table-expanded-striped table-expanded-head-custom table-expanded-vertical-center overflow-hidden"
						bordered={false}
						// remote
						{...tableProps}
						{...props.baseProps}
					/>
				</div>
			)}
		</ToolkitProvider>
	);
}
