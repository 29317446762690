import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { getAnswerByQuiz } from "../../../../api/answer";
import { getQuizById } from "../../../../api/quiz";
import { alertError } from "../../../../utils/logger";
import Table, {
  correctAnswerFormatter,
  timeFormatter,
} from "../../../components/tables/table";

function getData(answers) {
  let data = [];

  answers.forEach((answer) => {
    let elem = {
      group: answer.group?.fullName,
      score: answer?.score,
      time: answer.time,
    };
    data = data.concat(elem);
  });
  return data;
}

export default function ViewQuizPage() {
  const [data, setData] = useState([]);
  const [quiz, setQuiz] = useState("");
  const [scenario, setScenario] = useState("");
  const [refresh, setRefresh] = useState(false);
  const quizId = useParams().id;

  const defaultSorted = [
    {
      dataField: "score",
      order: "desc",
    },
  ];

  function headerFormatter(column, colIndex) {
    return typeof data[0]?.pts === "boolean" ? "Result" : "Points";
  }

  const columns = [
    { dataField: "group", text: "Group" },
    {
      dataField: "score",
      text: "Correct",
      align: "center",
      headerAlign: "center",
      formatter: correctAnswerFormatter,
      headerFormatter: headerFormatter,
      sort: true,
    },
    {
      dataField: "time",
      text: "Time",
      align: "center",
      headerAlign: "center",
      formatter: timeFormatter,
      sort: true,
    },
  ];

  useEffect(() => {
    getQuizById(quizId)
      .then((res) => {
        if (res.status === 200) {
          setQuiz(res.data.fullName);
          setScenario(res.data.scenario.fullName);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get quiz.",
        });
      });
    getAnswerByQuiz(quizId)
      .then((res) => {
        if (res.status === 200) {
          setData(getData(res.data));
          setRefresh(false);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get quiz.",
        });
      });
  }, [refresh]);

  return (
    <>
      <Card>
        <CardHeader title={`Quiz | ${quiz}`}>
          <CardHeaderToolbar>
            <div>
              Scenario <b>{scenario}</b>
            </div>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Table data={data} columns={columns} defaultSorted={defaultSorted} />
        </CardBody>
      </Card>
    </>
  );
}
